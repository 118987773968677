import { SET_LASTTRAVELBOOK } from '../actions/index'

// Mise à jour du nom du dernier carnet modifié
const lastTravelbookReducer = (state = '', action) => {
    if (action.type === SET_LASTTRAVELBOOK) {
        return action.payload
    }
    return state
}

export default lastTravelbookReducer