// Font Awesome 5.13
import './styles/fontawesome/css/travelsteps-fontawesome.min.css'
import './styles/fontawesome/css/solid.min.css'
import './styles/fontawesome/css/regular.min.css'

// Package Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'

// style global
import './index.css';


import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

// Redux Store
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import rootReducer from './reducers'

const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider> 
  , document.getElementById('travelsteps')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
