import { SET_LASTSTEP } from '../actions/index'

// Mise à jour de l'étape courante
const lastStepReducer = (state = '', action) => {
    if (action.type === SET_LASTSTEP) {
        return action.payload
    }
    return state
}

export default lastStepReducer