import { SET_APPONLINE } from '../actions/index'

// Mise à jour du toogle online / offline
const appOnLineReducer = (state = true, action) => {
    if (action.type === SET_APPONLINE) {
        return action.payload
    }
    return state
}

export default appOnLineReducer