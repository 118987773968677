import './App.scss';

import React, { lazy, Suspense } from 'react';

import { BrowserRouter, Switch, Route } from "react-router-dom"

//import Home from './containers/Home'
const Home = lazy(() => import('./containers/Home'));

//import LastNote from './containers/LastNote'
const LastNote = lazy(() => import('./containers/LastNote'));

//import LastStep from './containers/LastStep'
const LastStep = lazy(() => import('./containers/LastStep'));

//import LastTravelbook from './containers/LastTravelbook'
const LastTravelbook = lazy(() => import('./containers/LastTravelbook'));

const App = () => {

  return (
    <BrowserRouter>
      <Suspense fallback={<div>Chargement...</div>}>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/lastnote">
            <LastNote />
          </Route>
          <Route path="/laststep">
            <LastStep />
          </Route>
          <Route path="/lasttravelbook">
            <LastTravelbook />
          </Route>
        </Switch>
      </Suspense>
    </BrowserRouter>
  )
}

export default App