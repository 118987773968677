// Actions Redux

// Selection on line / off line
export const SET_APPONLINE = "SET_APPONLINE"

// Dernièr carnet de voyage modifié
export const SET_LASTTRAVELBOOK = "SET_LASTTRAVELBOOK"

// Dernière étape saisie
export const SET_LASTSTEP = "SET_LASTSTEP"

// json web token
export const SET_JWT = "SET_JWT"


// Selection on line / off line
export function setAppOnLine(payload) {
    return { type: SET_APPONLINE, payload }
}

// Dernière carnet modifié
export function setLastTravelbook(payload) {
    return { type: SET_LASTTRAVELBOOK, payload }
}

// Dernière étape saisie
export function setLastStep(payload) {
    return { type: SET_LASTSTEP, payload }
}

// json web token
export function setJwt(payload) {
    return { type: SET_JWT, payload }
}