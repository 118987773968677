// Reducers Redux

import { combineReducers } from 'redux'

// toggle online / offline
import appOnLineReducer from './appOnLineReducer'

// Dernier carnet de voyage
import lastTravelbookReducer from './lastTravelbookReducer'
// Dernière étape saisie
import lastStepReducer from './lastStepReducer'

// json web token
import jwtReducer from './jwtReducer'

const rootReducer = combineReducers({ 
    appOnLine: appOnLineReducer, 
    lastTravelbook: lastTravelbookReducer,
    lastStep: lastStepReducer,
    jwt: jwtReducer 
})

export default rootReducer